import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
import UnAuthLayout from "@/views/Pages/UnAuthLayout.vue";

// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Calendar/Calendar.vue");
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Charts.vue");

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Buttons.vue");
const Cards = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Cards.vue");
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/GridSystem.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Typography.vue"
  );

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue");
const AlternativeDashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Dashboard/AlternativeDashboard.vue"
  );
const Widgets = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Widgets.vue");

// Forms pages
const FormElements = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormElements.vue");
const FormComponents = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormComponents.vue");
const FormValidation = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormValidation.vue");

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/GoogleMaps.vue");
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/VectorMaps.vue");

// Pages
const UserProfile = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/UserProfile.vue");

const User = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/UserProfile.vue");
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/TimeLinePage.vue");
const MyHackathons = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Hackathon/Manage/MyHackathons.vue"
  );
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const ForgotPassword = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ForgotPassword.vue");
const ResetPassword = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ResetPassword.vue");
const Logout = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Logout.vue");
const LoginExpired = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/LoginExpired.vue");
const Home = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Home.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/RegularTables.vue");
const SortableTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/SortableTables.vue");
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/PaginatedTables.vue");

const TermsAndConditions = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/TermsAndConditions.vue"
  );
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/PrivacyPolicy.vue");
const AcceptableUsePolicy = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/AcceptableUsePolicy.vue"
  );
const RefundPolicy = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RefundPolicy.vue");

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "TimelinePage",
      component: TimeLine,
    },
  ],
};

let userPagesMenu = {
  path: "/user",
  component: DashboardLayout,
  name: "User",
  redirect: "/user/profile",
  children: [
    {
      path: "profile",
      name: "Profile",
      component: UserProfile,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};

//#region Organization
const OrganizationMembersCrud = () =>
  import("@/views/Pages/Organization/Members/CrudOrganizationMembers.vue");

const OrganizationMemberInvite = () =>
  import("@/views/Pages/Organization/Members/OrganizationMemberInvite.vue");

// const OrganizationSettings = () =>
//     import("@/views/Pages/Organization/Settings/OrganizationSettings.vue");

let organizationPages = {
  path: "/organization",
  component: DashboardLayout,
  name: "Organization",
  redirect: "/",
  children: [
    //#region Organization Members
    {
      path: ":organization_id/members",
      name: "OrganizationMembersCrud",
      component: OrganizationMembersCrud,
    },
    //#endregion

    //#region Organization Invites
    {
      path: "members/invite/view",
      name: "OrganizationMemberInvite",
      component: OrganizationMemberInvite,
    },
    //#endregion

    //#region Organization Settings
    // {
    //     path: ":organization_id/settings",
    //     name: "OrganizationSettings",
    //     component: OrganizationSettings,
    // },
    //#endregion
  ],
};

//#endregion

const HackathonViewIdea = () =>
  import("@/views/Pages/Hackathon/Idea/ViewIdea.vue");
const HackathonCrudIdea = () =>
  import("@/views/Pages/Hackathon/Idea/CrudIdea.vue");
const HackathonListIdeas = () =>
  import("@/views/Pages/Hackathon/Idea/ListIdeas.vue");

const ManageHackathons = () => import("@/views/Pages/HackathonsManage.vue");
const CrudHackathons = () => import("@/views/Pages/HackathonCrud.vue");

const HackathonProfile = () => import("@/views/Pages/HackathonProfile.vue");
const HackathonDiscover = () =>
  import("@/views/Pages/Hackathon/Discovery/Discover.vue");
const HackathonView = () => import("@/views/Pages/Hackathon/ViewHackathon.vue");
const HackathonTeam = () => import("@/views/Pages/Hackathon/Team/Team.vue");

const HackathonMyIdeas = () =>
  import("@/views/Pages/Hackathon/Idea/MyIdeas.vue");
const HackathonIdeas = () =>
  import("@/views/Pages/Hackathon/Idea/ListIdeas.vue");

const HackathonApproveIdeas = () =>
  import("@/views/Pages/Hackathon/Idea/ApproveIdeas.vue");
const HackathonApproveIdea = () =>
  import("@/views/Pages/Hackathon/Idea/ApproveIdea.vue");

const HackathonViewSchedule = () =>
  import("@/views/Pages/Hackathon/Schedule/ViewSchedule.vue");
const HackathonParticipants = () =>
  import("@/views/Pages/Hackathon/Participants/Participants.vue");

const HackathonJudgesCrud = () =>
  import("@/views/Pages/Hackathon/Judging/CrudJudges.vue");
const HackathonJudgeSubmission = () =>
  import("@/views/Pages/Hackathon/Judging/JudgeSubmission.vue");

const HackathonJudgingCriteriaCrud = () =>
  import("@/views/Pages/Hackathon/Judging/CrudJudgingCriteria.vue");

const HackathonSubmissionsCrud = () =>
  import("@/views/Pages/Hackathon/Judging/CrudSubmissions.vue");

const HackathonManagementTeamCrud = () =>
  import("@/views/Pages/Hackathon/ManagementTeam/CrudManagementTeam.vue");

const HackathonResourcesCrud = () =>
  import("@/views/Pages/Hackathon/Resources/CrudResources.vue");

const HackathonJudgingInvite = () =>
  import("@/views/Pages/Hackathon/Judging/JudgeInvite.vue");
const HackathonManagementInvite = () =>
  import("@/views/Pages/Hackathon/ManagementTeam/ManagementTeamInvite.vue");

const HackathonVolunteerInvite = () =>
  import("@/views/Pages/Hackathon/Volunteers/VolunteerInvite.vue");

const HackathonMentorInvite = () =>
  import("@/views/Pages/Hackathon/Mentors/MentorInvite.vue");

const HandleInvite = () => import("@/views/Pages/HandleInvite.vue");

const HackathonVolunteersCrud = () =>
  import("@/views/Pages/Hackathon/Volunteers/CrudVolunteers.vue");

const HackathonMentorsCrud = () =>
  import("@/views/Pages/Hackathon/Mentors/CrudMentors.vue");

const HackathonTimeline = () =>
  import("@/views/Pages/Hackathon/Manage/Timeline.vue");

const HackathonPrizesCrud = () =>
  import("@/views/Pages/Hackathon/Prizes/CrudPrizes.vue");

const HackathonGoalsCrud = () =>
  import("@/views/Pages/Hackathon/Goals/CrudGoals.vue");

const Dev = () => import("@/views/Pages//Dev.vue");

let hackathonPages = {
  path: "/hackathon",
  component: DashboardLayout,
  name: "Hackathon",
  redirect: "/hackathon/discover",
  children: [
    //#region Hackathon Manage
    {
      path: "dev",
      component: Dev,
      name: "Dev",
    },
    {
      path: "manage",
      name: "ManageHackathons",
      component: ManageHackathons,
    },
    {
      path: "mine",
      name: "MyHackathons",
      component: MyHackathons,
    },
    {
      path: ":hackathon_id/timeline",
      name: "HackathonTimeline",
      component: HackathonTimeline,
    },
    {
      path: "new/timeline",
      name: "HackathonTimeline",
      component: HackathonTimeline,
    },
    {
      path: "create",
      name: "CreateHackathon",
      component: CrudHackathons,
    },
    {
      path: ":hackathon_id/edit",
      name: "EditHackathon",
      component: CrudHackathons,
    },
    //#endregion

    //#region Hackathon Discover
    {
      path: "discover",
      name: "HackathonDiscover",
      component: HackathonDiscover,
    },
    {
      path: ":hackathon_id/detail",
      name: "HackathonView",
      component: HackathonView,
    },
    //#endregion

    //#region Hackathon Profile
    {
      path: "profile",
      name: "HackathonProfile",
      component: HackathonProfile,
    },
    //#endregion

    //#region Hackathon Ideas
    {
      path: ":hackathon_id/ideas/mine",
      name: "HackathonMyIdeas",
      component: HackathonMyIdeas,
    },
    {
      path: ":hackathon_id/ideas",
      name: "HackathonIdeas",
      component: HackathonIdeas,
    },
    {
      path: ":hackathon_id/ideas/approve",
      name: "HackathonApproveIdeas",
      component: HackathonApproveIdeas,
    },
    {
      path: ":hackathon_id/ideas/approve/:idea_id",
      name: "HackathonApproveIdea",
      component: HackathonApproveIdea,
    },
    {
      path: ":hackathon_id/idea/:idea_id/view",
      name: "HackathonViewIdea",
      component: HackathonViewIdea,
    },
    {
      path: ":hackathon_id/idea/:idea_id/view",
      name: "HackathonViewIdea",
      component: HackathonViewIdea,
    },
    {
      path: ":hackathon_id/idea/:idea_id/edit",
      name: "HackathonRudIdea",
      component: HackathonCrudIdea,
    },
    {
      path: ":hackathon_id/idea/create",
      name: "HackathonCrIdea",
      component: HackathonCrudIdea,
    },
    //#endregion

    //#region Hackathon Schedule
    {
      path: ":hackathon_id/schedule",
      name: "HackathonViewSchedule",
      component: HackathonViewSchedule,
    },
    //#endregion

    //#region Hackathon Team
    {
      path: ":hackathon_id/team/:team_id",
      name: "HackathonTeam",
      component: HackathonTeam,
    },
    //#endregion
    //#region Participants
    {
      path: ":hackathon_id/participants",
      name: "HackathonParticipants",
      component: HackathonParticipants,
    },
    //#endregion

    //#region Hackathon Resources
    {
      path: ":hackathon_id/resources",
      name: "HackathonResourcesCrud",
      component: HackathonResourcesCrud,
    },

    //#endregion

    //#region Hackathon Organizing Team

    {
      path: ":hackathon_id/organizing-team",
      name: "HackathonManagementTeamCrud",
      component: HackathonManagementTeamCrud,
    },

    //#endregion

    //#region Hackathon Judges
    {
      path: "/hackathon/organizing-team/invite/view",
      name: "HackathonJudgingInvite",
      component: HackathonManagementInvite,
    },

    {
      path: ":hackathon_id/judges",
      name: "HackathonJudgesCrud",
      component: HackathonJudgesCrud,
    },
    {
      path: "/hackathon/judging/invite/view",
      name: "HackathonJudgingInvite",
      component: HackathonJudgingInvite,
    },
    {
      path: ":hackathon_id/judging-criteria",
      name: "HackathonJudgingCriteriaCrud",
      component: HackathonJudgingCriteriaCrud,
    },
    {
      path: ":hackathon_id/submissions",
      name: "HackathonSubmissionsCrud",
      component: HackathonSubmissionsCrud,
    },
    {
      path: ":hackathon_id/submissions/:submission_id",
      name: "HackathonJudgeSubmission",
      component: HackathonJudgeSubmission,
    },

    //#endregion

    //#region Hackathon Volunteers
    {
      path: ":hackathon_id/heroes",
      name: "HackathonVolunteersCrud",
      component: HackathonVolunteersCrud,
    },
    {
      path: "/hackathon/heroes/invite/view",
      name: "HackathonVolunteerInvite",
      component: HackathonVolunteerInvite,
    },

    //#endregion

    //#region Hackathon mentors
    {
      path: ":hackathon_id/mentors",
      name: "HackathonMentorsCrud",
      component: HackathonMentorsCrud,
    },
    {
      path: "/hackathon/mentors/invite/view",
      name: "HackathonMentorInvite",
      component: HackathonMentorInvite,
    },

    //#endregion

    // This will be implemented later if at all.
    // {
    //     path: "idea/discover",
    //     name: "HackathonListIdeas",
    //     component: HackathonListIdeas,
    // },

    //#region Hackathon Prizes
    {
      path: ":hackathon_id/prizes",
      name: "HackathonPrizesCrud",
      component: HackathonPrizesCrud,
    },
    //#endregion
    //#region Hackathon Goals
    {
      path: ":hackathon_id/goals",
      name: "HackathonGoalsCrud",
      component: HackathonGoalsCrud,
    },
    //#endregion
  ],
};

// #region hackathonUnAuthPages

const UnAuthHackathonView = () =>
  import("@/views/Pages/HackathonUnauth/ViewHackathon.vue");

let hackathonUnAuthPages = {
  path: "/pub/hackathon",
  component: UnAuthLayout,
  name: "Hackathon",
  redirect: "/pub/hackathon/discover",

  children: [
    {
      path: ":hackathon_id/detail",
      name: "UnAuthHackathonView",
      component: UnAuthHackathonView,
      un_auth: true,
    },
  ],
};
// #endregion

import OnboardingLayout from "@/views/Pages/OnboardingLayout.vue";
import CompanyInformation from "@/views/Pages/Onboarding/CompanyInformation.vue";
import HostUsageInformation from "@/views/Pages/Onboarding/HostUsageInformation.vue";
import ParticipantInformation from "@/views/Pages/Onboarding/ParticipantInformation.vue";
import CompleteHackathonProfile from "@/views/Pages/Onboarding/CompleteHackathonProfile.vue";

let onboarding = {
  path: "/",
  component: OnboardingLayout,
  name: "Onboarding",
  children: [
    {
      path: "/welcome/company",
      name: "CompanyInformation",
      component: CompanyInformation,
    },
    {
      path: "/welcome/start",
      name: "HostUsageInformation",
      component: HostUsageInformation,
    },
    {
      path: "/welcome/participant",
      name: "ParticipantInformation",
      component: ParticipantInformation,
    },
    {
      path: "/welcome/complete-hackathon-profile",
      name: "CompleteHackathonProfile",
      component: CompleteHackathonProfile,
    },
  ],
};

let invites = {
  path: "/",
  component: AuthLayout,
  name: "Invites",
  children: [
    {
      path: "/hackathon/judging/invite",
      name: "HandleInvite",
      component: HandleInvite,
      un_auth: true,
    },
    {
      path: "/hackathon/organizing-team/invite",
      name: "HandleInvite",
      component: HandleInvite,
      un_auth: true,
    },
    {
      path: "/hackathon/mentors/invite",
      name: "HandleInvite",
      component: HandleInvite,
      un_auth: true,
    },
    {
      path: "/hackathon/heroes/invite",
      name: "HandleInvite",
      component: HandleInvite,
      un_auth: true,
    },
    {
      path: "/organization/members/invite",
      name: "HandleInvite",
      component: HandleInvite,
      un_auth: true,
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      un_auth: true,
    },
    {
      path: "/forgot_password",
      name: "ForgotPassword",
      component: ForgotPassword,
      un_auth: true,
    },

    {
      path: "/reset_password",
      name: "ResetPassword",
      component: ResetPassword,
      un_auth: true,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      un_auth: true,
    },
    {
      path: "/login-expired",
      name: "LoginExpired",
      component: LoginExpired,
      un_auth: true,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      un_auth: true,
    },
    {
      path: "/tnc",
      name: "TermsAndConditions",
      component: TermsAndConditions,
      un_auth: true,
    },
    {
      path: "/privacy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
      un_auth: true,
    },
    {
      path: "/acceptable-use",
      name: "AcceptableUsePolicy",
      component: AcceptableUsePolicy,
      un_auth: true,
    },
    {
      path: "/refunds",
      name: "RefundPolicy",
      component: RefundPolicy,
      un_auth: true,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
      un_auth: true,
    },

    { path: "*", component: NotFound },
  ],
};

const routes = [
  userPagesMenu,
  hackathonPages,
  organizationPages,
  {
    path: "/",
    redirect: "hackathon/dev",
    name: "Dash",
  },

  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dash",
    name: "Dashboard layout",
    children: [
      {
        path: "dash",
        name: "Dashboard",
        component: Dashboard,
      },
    ],
  },
  hackathonUnAuthPages,
  invites,
  onboarding,
  authPages,
];

export default routes;

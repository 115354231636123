var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":"img/brand/logos/otingaLandscape.svg"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Hackathons',
          icon: 'fas fa-lightbulb  text-gray',
          path: '/hackathon',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Discover',
            path: '/hackathon/discover',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'My Hackathons',
            path: '/hackathon/mine',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Hackathon Profile',
            path: '/hackathon/profile',
          }}})],1),(_vm.$apollo.queries.get_active_hackathon.loading)?_c('sidebar-item',{scopedSlots:_vm._u([{key:"rawcontent",fn:function(){return [_c('i',{staticClass:"fas fa-spinner fa-spin text-gray ml-4"})]},proxy:true}],null,false,399101698)}):_vm._e(),_vm._l((_vm.hackathons),function(hackathon){return _c('sidebar-item',{key:hackathon.id,attrs:{"link":{
          name: hackathon.name,
          icon: 'fas fa-lightbulb  text-gray',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Home',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/detail"),
          }}}),(
            hackathon.managementTeamVisible ||
            hackathon.isManager ||
            hackathon.isVolunteer ||
            hackathon.isMentor
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'Organizing Team',
            path: ("/hackathon/" + (encodeURIComponent(
              hackathon.id
            )) + "/organizing-team"),
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Participants',
            path: ("/hackathon/" + (encodeURIComponent(
              hackathon.id
            )) + "/participants"),
          }}}),(
            'myTeam' in hackathon &&
            hackathon.myTeam !== null &&
            'id' in hackathon.myTeam
          )?_c('sidebar-item',{attrs:{"link":{
            name: 'My Team',
            path: ("/hackathon/" + (encodeURIComponent(
              hackathon.id
            )) + "/team/" + (encodeURIComponent(hackathon.myTeam.id))),
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Ideas',
            icon: 'fas fa-lightbulb  text-gray',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Hackathon Ideas',
              path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/ideas"),
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'My Ideas',
              path: ("/hackathon/" + (encodeURIComponent(
                hackathon.id
              )) + "/ideas/mine"),
            }}}),(hackathon.isManager && hackathon.ideasRequireApproval)?_c('sidebar-item',{attrs:{"link":{
              name: 'Approve Ideas',
              path: ("/hackathon/" + (encodeURIComponent(
                hackathon.id
              )) + "/ideas/approve"),
            }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
            name: 'Schedule',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/schedule"),
          }}}),(hackathon.hasResources)?_c('sidebar-item',{attrs:{"link":{
            name: 'Resources',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/resources"),
          }}}):_vm._e(),(hackathon.hasMentors)?_c('sidebar-item',{attrs:{"link":{
            name: 'Mentors',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/mentors"),
          }}}):_vm._e(),(hackathon.hasVolunteers)?_c('sidebar-item',{attrs:{"link":{
            name: 'Heroes',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/heroes"),
          }}}):_vm._e(),(hackathon.hasJudges)?_c('sidebar-item',{attrs:{"link":{
            name: 'Judging',
            icon: 'fas fa-lightbulb  text-gray',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Judges',
              path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/judges"),
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Criteria',
              path: ("/hackathon/" + (encodeURIComponent(
                hackathon.id
              )) + "/judging-criteria"),
            }}}),(hackathon.isManager || hackathon.isJudge)?_c('sidebar-item',{attrs:{"link":{
              name: 'Submissions',
              path: ("/hackathon/" + (encodeURIComponent(
                hackathon.id
              )) + "/submissions"),
            }}}):_vm._e()],1):_vm._e(),(hackathon.hasPrizes)?_c('sidebar-item',{attrs:{"link":{
            name: 'Prizes',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/prizes"),
          }}}):_vm._e(),(hackathon.isCreator || hackathon.isManager)?_c('sidebar-item',{attrs:{"link":{
            name: 'Goals',
            path: ("/hackathon/" + (encodeURIComponent(hackathon.id)) + "/goals"),
          }}}):_vm._e()],1)}),_c('sidebar-item',{attrs:{"link":{
          name: 'Settings',
          icon: 'ni ni-settings text-gray',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Profile', path: '/user/profile' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Organization' }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Members',
              path: ("/organization/" + (encodeURIComponent(
                _vm.active_organization.id
              )) + "/members"),
            }}})],1),(_vm.$apollo.queries.get_user_organization.loading)?_c('sidebar-item',{scopedSlots:_vm._u([{key:"rawcontent",fn:function(){return [_c('i',{staticClass:"fas fa-spinner fa-spin text-gray ml-5"})]},proxy:true}],null,false,906156323)}):_vm._e()],1)],2)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{staticClass:"router-view-content"})],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import gql from "graphql-tag";

// export const GET_USER_PROFILE_CONTACT_DETAIL = gql`

// `;

export const GET_USER_PROFILE = gql`
    query ($user_id: ID!) {
        allUserProfile(user: $user_id) {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

export const GET_ALL_USER_ACCOUNT_ACCOUNT = gql`
    query ($user: ID!) {
        allUserAccountAccount(owner: $user) {
            edges {
                node {
                    id
                    uid
                }
            }
        }
    }
`;

export const GET_HACKATHON_HACKATHON = gql`
    query ($id: ID!) {
        hackathonHackathon(id: $id) {
            id
            uid
            name
            description
            detailDescription
            startDatetime
            endDatetime
            isParticipant
            isManager
            isCreator
            allowedParticipants
            maxTeamSize
            organization {
                id
                name
            }
            status
            statusDisplay
            phase
            phaseDisplay
            hasJudges
            hasPrizes
            hasMentors
            hasPitches
            hasVolunteers
            hasKickoffSession
            hasClosingSession
            organization {
                id
                name
            }
            hasGoals
            managementTeamVisible
            creator {
                id
                firstName
                lastName
                email
            }
            hasGoals
            ideasRequireApproval
        }
    }
`;

export const GET_HACKATHON_HACKATHON_REGISTER_BUTTON = gql`
    query ($hackathon_id: ID!) {
        hackathonHackathon(id: $hackathon_id) {
            id
            name
            isParticipant
            myParticipantId
            status
            isBanned
        }
    }
`;

export const GET_HACKATHON_HACKATHON_BASIC = gql`
    query ($hackathon_id: ID!) {
        hackathonHackathon(id: $hackathon_id) {
            id
            name
            isParticipant
            myParticipantId
            status
            isBanned
            ideasRequireApproval
        }
    }
`;



// Hackahton Competition Categories Team
export const GET_HACKATHON_COMPETITION_CATEGORIES = gql`
    query ($hackathon_id: ID!) {
        hackathonHackathon(id: $hackathon_id) {
            id
            competitionCategories {
                edges {
                    node {
                        id
                        name
                        default
                        submissionTemplate {
                            id
                            schema
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ALL_HACKATHON_HACKATHON_ORGANIZING = gql`
    query {
        allHackathonHackathon(organizing: true) {
            edges {
                node {
                    id
                    name
                    description
                    isManager
                    isCreator
                    isParticipant
                    myTeam {
                        id
                    }
                    startDatetime
                    phaseDisplay
                    organization {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_ALL_HACKATHON_HACKATHON_PARTICIPATING = gql`
    query {
        allHackathonHackathon(participating: true) {
            edges {
                node {
                    id
                    name
                    description
                    isManager
                    isCreator
                    isParticipant
                    myTeam {
                        id
                    }
                    startDatetime
                    phaseDisplay
                    organization {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_ALL_HACKATHON_HACKATHON_INVOLVED = gql`
    query {
        allHackathonHackathon(uiView: true) {
            edges {
                node {
                    id
                    name
                    managementTeamVisible
                    hasJudges
                    hasPrizes
                    hasMentors
                    hasVolunteers
                    hasResources
                    isManager
                    isCreator
                    isParticipant
                    myTeam {
                        id
                    }
                }
            }
        }
    }
`;

export const GET_HACKATHON_SIDEBAR = gql`
    query ($hackathon_id: ID!) {
        hackathonHackathon(id: $hackathon_id) {
            id
            name
            managementTeamVisible
            hasJudges
            hasPrizes
            hasMentors
            hasVolunteers
            hasResources
            isManager
            isCreator
            isJudge
            isMentor
            isVolunteer
            isParticipant
            myTeam {
                id
            }
            ideasRequireApproval
        }
    }
`;

export const GET_ALL_HACKATHON_HACKATHON_DISCOVERY = gql`
    query {
        allHackathonHackathon(discover: true) {
            edges {
                node {
                    id
                    name
                    description
                    startDatetime
                    endDatetime
                    phase
                    phaseDisplay
                    isManager
                    isCreator
                    startDatetime
                    isParticipant
                }
            }
        }
    }
`;

export const GET_HACKATHON_FROM_USER = gql`
    query ($creator: ID) {
        allHackathonHackathon(creator: $creator) {
            edges {
                node {
                    id
                    name
                    description
                    detailDescription
                    allowedParticipants
                    maxTeamSize
                    startDatetime
                    endDatetime
                    creator {
                        id
                        username
                    }
                    organization {
                        id
                        name
                    }
                    statusDisplay
                    status
                }
            }
        }
    }
`;

export const GET_HACKATHON_PROFILE = gql`
    query {
        hackathonProfileProfile(me: true) {
            edges {
                node {
                    id
                    user {
                        id
                        username
                    }
                    linkedinUrl
                    skills {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    bio
                }
            }
        }
    }
`;

export const GET_HACKATHON_PROFILE_FROM_USER_ID = gql`
    query ($user: ID) {
        allHackathonProfileProfile(user: $user) {
            edges {
                node {
                    id
                    user {
                        id
                        username
                    }
                    linkedinUrl
                    skills {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    bio
                }
            }
        }
    }
`;

export const GET_PROFILE_SKILL = gql`
    query {
        allHackathonProfileSkill {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_HACKATHON_USER_LIKED = gql`
    query ($creator: ID!, $idea: [ID]!) {
        allLikeLike(creator: $creator, idea: $idea) {
            edges {
                node {
                    id
                    idea {
                        edges {
                            node {
                                id
                            }
                        }
                    }
                    creator {
                        id
                        username
                    }
                }
            }
        }
    }
`;

// New

// Hackathons

// Hackathon Ideas

export const GET_HACKATHON_IDEA_IDEA = gql`
    query ($idea_id: ID!) {
        hackathonIdeaIdea(id: $idea_id) {
            id
            creator {
                id
            }
            account {
                id
            }
            name
            description
            detailDescription
            isHackathonManager
            isCreator
            approval{
                id
            }
            hackathon{
                id
                ideasRequireApproval
            }
            team {
                id
                totalMembers
                hasRequestedToJoin
                isMember
            }
        }
    }
`;

export const GET_HACKATHON_IDEA_IDEA_APPROVAL = gql`
    query ($idea_id: ID!) {
        hackathonIdeaIdea(id: $idea_id) {
            id
            creator {
                id
            }
            account {
                id
            }
            name
            description
            detailDescription
            isHackathonManager
            isCreator
            team {
                id
                totalMembers
                hasRequestedToJoin
                isMember
            }
            approval {
                id
            }
        }
    }
`;

export const GET_HACKATHON_IDEAS = gql`
    query ($hackathon_id: ID!) {
        allHackathonIdeaIdea(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    name
                    creator {
                        id
                        username
                    }
                    description
                    created
                    isCreator
                    hackathon {
                        maxTeamSize
                    }
                    team {
                        id
                        name
                        totalMembers
                        isMember
                    }
                }
            }
        }
    }
`;

export const GET_HACKATHON_MY_IDEAS = gql`
    query ($hackathon_id: ID!, $user_id: ID!) {
        allHackathonIdeaIdea(
            hackathon: $hackathon_id
            creator: $user_id
            all: true
        ) {
            edges {
                node {
                    id
                    name
                    creator {
                        id
                        username
                    }
                    description
                    created
                    isCreator
                    hackathon {
                        id
                        maxTeamSize
                      ideasRequireApproval
                    }
                    team {
                        id
                        name
                        totalMembers
                        isMember
                    }
                    approval{
                        id
                        state{
                          id
                          name
                          displayName
                        }
                      }
                }
            }
        }
    }
`;
export const GET_HACKATHON_IDEA_AI_FEEDBACK = gql`
query(
    $idea_id:ID!
  ){
    hackathonIdeaIdea(id: $idea_id){
      id
      aiFeedback(last:1){
        edges{
          node{
            id
            response
  
          }
        }
      }
    }
  }
`;
export const GET_HACKATHON_APPROVAL_IDEAS = gql`
    query ($hackathon_id: ID!) {
        allHackathonIdeaIdea(hackathon: $hackathon_id all:true) {
            edges {
                node {
                    id
                    name
                    creator {
                        id
                        firstName
                        lastName
                        email
                    }
                    approval {
                        id
                        state {
                            id
                            name
                            displayName
                        }
                    }
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;

// Hackathon Program

export const GET_HACKATHON_PROGRAM = gql`
    query ($hackathon_id: ID!) {
        hackathonHackathon(id: $hackathon_id) {
            id
            name
            isCreator
            isManager
            programItems {
                edges {
                    node {
                        id
                        name
                        description
                        startTime
                        endTime
                        links {
                            edges {
                                node {
                                    id
                                    name
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

// Hackathon Team
export const GET_HACKATHON_TEAM = gql`
    query ($team_id: ID!) {
        hackathonTeam(id: $team_id) {
            id
            name
            isMember
            isAdmin
        }
    }
`;

export const GET_HACKATHON_TEAM_SUBMISSIONS = gql`
    query ($team_id: ID!) {
        allHackathonTeamSubmission(team: $team_id) {
            edges {
                node {
                    id
                    response
                    submitted
                    template {
                        id
                        schema
                    }
                    competitionCategory {
                        id
                        name
                        default
                    }
                }
            }
        }
    }
`;

export const GET_HACKATHON_SUBMISSION = gql`
    query ($submission_id: ID!) {
        hackathonTeamSubmission(id: $submission_id) {
            id
            response
            submitted
            template {
                id
                schema
            }
            team {
                id
                name
            }
            competitionCategory {
                id
                name
                default
            }
        }
    }
`;

export const GET_HACKATHON_JUDGING_SUBMISSIONS = gql`
    query ($hackathon_id: ID!) {
        allHackathonTeamSubmission(
            team_Hackathon: $hackathon_id
            judging: true
        ) {
            edges {
                node {
                    id
                    team {
                        id
                        name
                    }
                    submitted
                    lateSubmission
                    judgingStatus
                    myJudgingStatus
                    judgingProgress
                    totalJudges
                    myScore
                    maxScore
                    averageScore
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
            isJudge
        }
    }
`;

// Hackathon Team Members
export const GET_HACKATHON_TEAM_MEMBERS = gql`
    query ($team_id: ID!) {
        allHackathonTeamTeamMember(team: $team_id, status: "active") {
            edges {
                node {
                    id
                    profile {
                        id
                        user {
                            id
                            firstName
                            lastName
                        }
                        linkedinUrl
                    }
                    role
                    isMe
                }
            }
        }
        hackathonTeam(id: $team_id) {
            id
            hackathon{
                id
            }
            isMember
            isAdmin
        }
    }
`;
export const GET_HACKATHON_TEAM_JOIN_REQUESTS = gql`
    query ($team_id: ID!) {
        allHackathonTeamTeamJoinRequest(status: "new", team: $team_id) {
            edges {
                node {
                    id
                    profile {
                        user {
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`;

// Hackathon Team Resource

export const GET_HACKATHON_TEAM_RESOURCES = gql`
    query ($team_id: ID!) {
        allHackathonTeamTeamResource(team: $team_id) {
            edges {
                node {
                    id
                    name
                    url
                    teamResourceTemplateItem {
                        id
                    }
                }
            }
        }
        hackathonTeam(id: $team_id) {
            id
            isAdmin
            isMember
        }
    }
`;

// Hackathon Resources

export const GET_HACKATHON_RESOURCES = gql`
    query ($hackathon_id: ID!) {
        allHackathonResourcesResource(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    name
                    description
                    url
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;

// Hackathon Organizing Team

export const GET_HACKATHON_MANAGEMENT_TEAM_MEMBERS = gql`
    query ($hackathon_id: ID!) {
        allHackathonManagementTeamMember(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    role
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
        }
    }
`;

// export const GET_HACKATHON_MANAGEMENT_TEAM_MEMBER_INVITES = gql`
//     query ($hackathon_id: ID, $invite_uid: UUID) {
//         allHackathonManagementTeamMemberInvite(
//             hackathon: $hackathon_id
//             uid: $invite_uid
//         ) {
//             edges {
//                 node {
//                     id
//                     uid
//                     email
//                     inviter {
//                         firstName
//                         lastName
//                     }
//                 }
//             }
//         }
//     }
// `;
export const GET_HACKATHON_MANAGEMENT_TEAM_MEMBER_INVITE = gql`
    query ($hackathon_id: ID, $invite_uid: UUID) {
        allHackathonManagementTeamMemberInvite(
            hackathon: $hackathon_id
            status: "pending"
            uid: $invite_uid
            first: 1
        ) {
            edges {
                node {
                    id
                    uid
                    email
                    status
                    hackathon {
                        id
                        name
                        creator {
                            email
                        }
                    }
                }
            }
        }
    }
`;

// Hackathon Judges
export const GET_HACKATHON_JUDGES = gql`
    query ($hackathon_id: ID!, $status: String) {
        allHackathonJudgingJudge(hackathon: $hackathon_id, status: $status) {
            edges {
                node {
                    id
                    status
                    profile {
                        user {
                            firstName
                            lastName
                        }
                        linkedinUrl
                    }
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;
export const GET_HACKATHON_JUDGE_INVITE = gql`
    query ($invite_uid: UUID!) {
        allHackathonJudgingJudgeInvite(
            status: "pending"
            uid: $invite_uid
            first: 1
        ) {
            edges {
                node {
                    id
                    uid
                    hackathon {
                        id
                        name
                        creator {
                            email
                        }
                    }
                }
            }
        }
    }
`;

export const GET_HACKATHON_SUBMISSION_SCORES = gql`
    query ($submission_id: ID!) {
        allHackathonJudgingScore(submission: $submission_id, judging: true) {
            edges {
                node {
                    id
                    completed
                    score
                    submission {
                        id
                        competitionCategory {
                            id
                        }
                    }
                    criteria {
                        id
                        maxScore
                        type
                        name
                        description
                    }
                }
            }
        }
    }
`;

// Hackathon Mentors
export const GET_HACKATHON_MENTORS = gql`
    query ($hackathon_id: ID!, $status: String) {
        allHackathonEventTeamMentor(hackathon: $hackathon_id, status: $status) {
            edges {
                node {
                    id
                    status
                    profile {
                        user {
                            firstName
                            lastName
                            email
                        }
                        linkedinUrl
                    }
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;
export const GET_HACKATHON_MENTOR_INVITE = gql`
    query ($invite_uid: UUID!) {
        allHackathonEventTeamMentorInvite(
            status: "pending"
            uid: $invite_uid
            first: 1
        ) {
            edges {
                node {
                    id
                    uid
                    hackathon {
                        id
                        name
                        creator {
                            email
                        }
                    }
                }
            }
        }
    }
`;

// Hackathon Volunteers
export const GET_HACKATHON_VOLUNTEERS = gql`
    query ($hackathon_id: ID!, $status: String) {
        allHackathonEventTeamVolunteer(
            hackathon: $hackathon_id
            status: $status
        ) {
            edges {
                node {
                    id
                    status
                    profile {
                        user {
                            firstName
                            lastName
                            email
                        }
                        linkedinUrl
                    }
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;

export const GET_HACKATHON_VOLUNTEER_INVITE = gql`
    query ($invite_uid: UUID!) {
        allHackathonEventTeamVolunteerInvite(
            status: "pending"
            uid: $invite_uid
            first: 1
        ) {
            edges {
                node {
                    id
                    uid
                    hackathon {
                        id
                        name
                        creator {
                            email
                        }
                    }
                }
            }
        }
    }
`;

// Hackathon Participants
export const GET_HACKATHON_PARTICIPANTS = gql`
    query ($hackathon_id: ID!) {
        allHackathonParticipantParticipant(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    hackathonProfile {
                        user {
                            firstName
                            lastName
                            email
                        }
                        linkedinUrl
                    }
                    banned
                }
            }
        }
        hackathonHackathon(id:$hackathon_id){
            id
            uid
        }
    }
`;

// Organization

export const GET_ORGANIZATION_MEMBERS = gql`
    query ($organization_id: ID!) {
        allOrganizationMember(organization: $organization_id) {
            edges {
                node {
                    id
                    role
                    user {
                        id
                        email
                        firstName
                        lastName
                    }
                }
            }
        }
        organization(id: $organization_id) {
            id
            userMembershipRole
        }
    }
`;
export const GET_ORGANIZATION_ASSOCIATED = gql`
    query {
        allOrganization(associatedOrganizations: true) {
            edges {
                node {
                    id
                    name
                    userMembershipRole
                }
            }
        }
    }
`;

export const GET_USER_ORGANIZATION = gql`
    query ($user_id: ID!) {
        allOrganization(creator: $user_id) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_ORGANIZATION_MEMBER_INVITE = gql`
    query ($invite_uid: UUID, $organization_uid: ID, $status: String) {
        allOrganizationMemberInvite(
            status: $status
            organization: $organization_uid
            uid: $invite_uid
            first: 1
        ) {
            edges {
                node {
                    id
                    uid
                    email
                    status
                    organization {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_HACKATHON_PRIZES = gql`
    query ($hackathon_id: ID!) {
        allHackathonPrizes(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    active
                    description
                    position
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;
export const GET_HACKATHON_JUDGING_CRITERIA = gql`
    query ($hackathon_id: ID!) {
        allHackathonJudgingCriteria(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    name
                    description
                    maxScore
                    type
                    typeDisplay
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;

export const GET_HACKATHON_GOALS = gql`
    query ($hackathon_id: ID!) {
        allHackathonGoal(hackathon: $hackathon_id) {
            edges {
                node {
                    id
                    name
                    priority
                    description
                }
            }
        }
        hackathonHackathon(id: $hackathon_id) {
            id
            isCreator
            isManager
        }
    }
`;

// #region AI
export const GET_AI_SERVICES = gql`
    query ($service_name: String) {
        allAiService(name: $service_name) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
export const GET_AI_QUERY = gql`
    query ($query_id: ID!) {
        aiQuery(id: $query_id) {
            id
            response
            errored
            success
        }
    }
`;
// #endregion

// #region Approval
export const GET_APPROVAL_APPROVAL_DETAIL = gql`
    query ($approval_id: ID!) {
        approvalApproval(id: $approval_id) {
            state {
                id
                name
                displayName
            }
            itemType {
                id
                supportedStates {
                    edges {
                        node {
                            id
                            name
                            displayName
                        }
                    }
                }
            }
            stateUpdates {
                edges {
                    node {
                        id
                        newState {
                            id
                            name
                            displayName
                        }
                        comment
                        created
                    }
                }
            }
        }
    }
`;

// #endregion
